<template>
  <a-row type="flex" :justify="justify">
    <a-col
      :xxl="14"
      :xl="20"
      :lg="22"
      :md="24"
      :sm="24"
      :xs="24"
      :style="{ padding: padding }">
      <slot />
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'Container',
  props: {
    padding: {
      type: String,
      default: '0 12px'
    },
    justify: {
      type: String,
      default: 'center'
    }
  }
}
</script>

<style scoped>

</style>
