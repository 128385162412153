import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import App from './App.vue'
import 'ant-design-vue/dist/antd.less'
import router from './router'
import Container from '@/components/common/Container'
import './config/rem'

const app = createApp(App, Antd)
app.component(Container.name, Container)
app.config.productionTip = false
app.use(router)
app.use(Antd)
app.mount('#app')
