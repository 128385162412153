<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
@import "assets/css/main.less";

#app {

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', sans-serif;
  color: #767676;

}
</style>
