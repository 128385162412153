import { createRouter, createWebHistory } from 'vue-router'

const routes = [

  {
    path: '/',
    name: '',
    redirect: '/index',
    component: () => import('@/layouts/BasicLayout'),
    children: [
      {
        path: '/index',
        name: 'home',
        component: () => import('@/views/Home'),
        meta: { title: '辰宇投资', icon: 'home' }
      },
      {
        path: '/join-us',
        name: 'joinUs',
        component: () => import('@/views/JoinUs'),
        meta: { title: '辰宇-加入我们', icon: 'user' }
      },
      {
        path: '/consultant',
        name: 'consultant',
        component: () => import('@/views/Consultant'),
        meta: { title: '辰宇-顾问', icon: 'user' }
      }
    ]
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: () => import('@/views/Introduce'),
    meta: { title: '辰宇app', icon: 'user' }
  },
  {
    path: '/introduce-haijing',
    name: 'introduce-haijing',
    component: () => import('@/views/Introduce_haijing'),
    meta: { title: '辰宇app', icon: 'user' }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/Privacy'),
    meta: { title: '隐私政策', icon: 'user' }
  },
  {
    path: '/privacy-haijing',
    name: 'privacy-haijing',
    component: () => import('@/views/Privacy_haijing'),
    meta: { title: '隐私政策', icon: 'user' }
  },
  {
    path: '/succeed/:name',
    name: 'succeed',
    component: () => import('@/views/Succee'),
    meta: { title: '辰宇', icon: 'user' }
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
